@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

.sidebar:before {
  content: '';
  position: absolute;
  width: 220px;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: white;
  border: none;
}

.Toastify__toast--success {
  background-color: #00984b !important;
}

.Toastify__toast--error {
  background-color: #d93400 !important;
}

/* 
----------------------------------------------------------
  Individual page layout style:
----------------------------------------------------------
*/
#filaPrimeraDreta {
  max-height: 320px !important;
}

.genereGrupDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 36px !important;
}

.singlesImg,
.couplesImg {
  height: 165px !important;
  width: 165px !important;
}
/* 
----------------------------------------------------------
  End of individual page layout style.
----------------------------------------------------------
*/

/* 
----------------------------------------------------------
  Couples page layout style:
----------------------------------------------------------
*/
/* First row style: */
#filaPrimeraTown {
  padding-top: 1%;
  padding-bottom: 1%;
}

/* Second row style: */
#segonaFilaNom1,
#segonaFilaNom2 {
  padding-top: 1%;
}

/* Third row style: */
#terceraFilaContacte,
#terceraFilaContacte2 {
  padding-bottom: 1%;
}

#segonaFilaDreta,
#segonaFilaDreta2 {
  max-height: 320px !important;
}

.css-319lph-ValueContainer {
  padding: 4px 8px !important;
}
/* 
----------------------------------------------------------
  End of couples page layout style.
----------------------------------------------------------
*/

.txtStudiesField {
  min-height: 56px !important;
}

/* 
----------------------------------------------------------
  Tags page layout style:
----------------------------------------------------------
*/
.tagPrint {
  display: none;
}
/* 
----------------------------------------------------------
  End of tags page layout style:
----------------------------------------------------------
*/

/* 
----------------------------------------------------------
  Individual and couples page print style:
----------------------------------------------------------
*/
@media print {
  html,
  body {
    padding: 0px !important;
    margin: 0px !important;
  }
  /* 
  ----------------------------------------------------------
    Individual page print style:
  ----------------------------------------------------------
  */
  #divGlobal {
    padding-top: 4% !important;
  }

  .genereGrupDiv {
    height: 50% !important;
    padding-bottom: 5% !important;
  }

  #textSGenere,
  #textSGrup {
    width: 50% !important;
  }

  .singlesImg {
    border-width: 5px !important;
    width: 90px !important;
    height: 90px !important;
  }

  #marcFoto {
    margin-top: 0% !important;
    margin-bottom: 0% !important;
    height: 50% !important;
  }

  #filaPrimeraEsquerra div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }

  #filaTercera {
    padding-top: 0px !important;
    margin-top: -2% !important;
  }
  /* 
  ----------------------------------------------------------
    End of individual page print style.
  ----------------------------------------------------------
  */

  /* 
  ----------------------------------------------------------
    Couples page print style:
  ----------------------------------------------------------
  */
  /* First row style: */
  #filaPrimeraTown {
    padding-top: 4%;
    padding-bottom: 4%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  /* Second row style: */
  #segonaFilaNom1,
  #segonaFilaNom2 {
    padding-top: 4%;
  }

  /* Third row style: */
  #terceraFilaContacte,
  #terceraFilaContacte2 {
    padding-bottom: 4%;
  }

  /* Person image style: */
  .couplesImg {
    border-width: 5px !important;
    width: 90px !important;
    height: 90px !important;
  }

  #marcFoto1,
  #marcFoto2 {
    margin-top: 0% !important;
    margin-bottom: 0% !important;
    height: 50% !important;
  }

  /* Gender input style: */
  #txtGenere,
  #txtGenere2 {
    height: 50% !important;
    padding-bottom: 3.5% !important;
  }

  /* Studies input style: */
  #txtStudies,
  #txtStudies2 {
    display: none !important;
  }

  /* General input style: */
  input {
    font-size: 12px !important;
    box-shadow: none !important;
    border: none !important;
    border-bottom: 1px solid green !important;
    border-radius: 0px !important;
    outline: none !important;
    padding-left: 0px !important;
  }

  /* General 'Select' inputs style: */
  .selectClass {
    font-size: 12px !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: 0px !important;
    outline: none !important;
    padding-left: 0px !important;
  }

  .selectClass div {
    font-size: 12px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    padding: 0px !important;
    margin: -0.7% 0px 0px 0px !important;
  }

  .selectClass .css-1s2u09g-control {
    border-bottom: 1px solid green !important;
  }

  .selectClass div div div input {
    border: none !important;
  }

  .selectClass svg {
    display: none !important;
  }

  /* General label style: */
  label {
    margin-top: 15px !important;
  }

  /* Second row 'div' display: */
  #segonaFilaEsquerra div,
  #txtGenere,
  #segonaFilaEsquerra2 div,
  #txtGenere2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
  /* 
  ----------------------------------------------------------
    End of couples page print style.
  ----------------------------------------------------------
  */
  /* 
  ----------------------------------------------------------
    Tags page print style:
  ----------------------------------------------------------
  */
  .tagPrint {
    display: grid !important;
  }
  /* 
  ----------------------------------------------------------
    End of tags page print style:
  ----------------------------------------------------------
  */

  @page {
    size: A4 portrait;
    margin-right: 0cm;
    margin-bottom: 0.85cm;
    margin-left: 0cm;
    margin-top: 0.85cm;
    padding: 0cm;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #fff;
  }

  /* .gridPrint:nth-of-type(2n){
    page-break-after: always !important;
    break-after: always;
  } */

  .gridPrint:nth-child(24n) {
    clear: both;
    page-break-after: always;
    break-after: always;
  }
}
/* 
----------------------------------------------------------
  End of individual and couples page print style.
----------------------------------------------------------
*/
.flex.flex-row.col-span-1.relative {
  margin-bottom: 15px;
}

.lg\:text-2xl {
  font-size: 1.3rem !important;
}

input::file-selector-button {
  background-color: rgb(167 243 208);
  border: 2px solid rgb(52 211 153);
  border-radius: 2px 2px 2px 2px;
  outline: none;
  padding: 0px 20px 0px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

#sidebar-menu::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
}

#sidebar-menu::-webkit-scrollbar-track {
  background: rgb(20 83 45);
  /* color of the tracking area */
  padding: 5px 2px 5px 0px;
}

#sidebar-menu::-webkit-scrollbar-thumb {
  background-color: rgb(220 252 231);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}
